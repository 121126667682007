.photo-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.photo {
  flex: 1 1;
  max-width: calc(33.33% - 10px);
}

table,
td,
th {
  background: #fff;
  border: 1px solid #000;
  border-collapse: collapse;
  font-size: 1rem;
  padding: 10px;
  text-align: center;
}

.tab-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  /* background-color: #E6EAEE; */
  /* padding: 10px 10px; */
  margin-bottom: 10px;
}

.tab-container .tabs {
  padding: 5px 20px;
  font-size: 13px;
  margin-right: 10px;
  cursor: pointer;
}

.tab-container .tabs:hover {
  background-color: rgb(222, 221, 221);
  border-radius: 5px;
}

.tab-container .tabs.active {
  background-color: #174276;
  border-radius: 5px;
  color: white;
}

.main_header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #174276;
  height: 6vh;
  color: white;
  align-items: center;
}

.add_lead {
  font-weight: 400;
}

.close {
  margin-right: 0.6rem;
  font-size: 27px;
  cursor: pointer;
}
